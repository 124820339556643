@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Work+Sans:wght@200;400;600;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Work+Sans:wght@200;400;600;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&family=Montagu+Slab:opsz,wght@16..144,100;16..144,200;16..144,300;16..144,400;16..144,500;16..144,600;16..144,700&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Work+Sans:wght@200;400;600;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jaini&family=Reddit+Mono:wght@200..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400..700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;
  

/*To not to show the scroll-bar*/
.no-scroll::-webkit-scrollbar{
    width: 0px; 
    height: 0px; 
}
  


  
/*Wave animation : The hand on Intro*/
.wave {
    animation-name: wave-animation; /* Refers to the name of your @keyframes element below */
    animation-duration: 2.1s; /* Change to speed up or slow down */
    animation-iteration-count: infinite; /* Never stop waving :) */
    transform-origin: 70% 70%; /* Pivot around the bottom-left palm */
    display: inline-block;
  }
  
  @keyframes wave-animation {
    0% {
      transform: rotate(0deg);
    }
    10% {
      transform: rotate(14deg);
    } /* The following five values can be played with to make the waving more or less extreme */
    20% {
      transform: rotate(-8deg);
    }
    30% {
      transform: rotate(14deg);
    }
    40% {
      transform: rotate(-4deg);
    }
    50% {
      transform: rotate(10deg);
    }
    60% {
      transform: rotate(0deg);
    } /* Reset for the last half to pause */
    100% {
      transform: rotate(0deg);
    }
  }
  


  
  /*Scroll down exist on the middle of Intro and Readme*/
  .scroll-down {
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .scroll-down span {
    display: block;
    width: 16px;
    height: 16px;
    border-bottom: 2px solid white;
    border-right: 2px solid white;
    transform: rotate(45deg);
    margin: -10px;
    animation: scroll-down 1.5s infinite;
  }
  
  .scroll-down span:nth-child(2) {
    animation-delay: -0.2s;
  }
  
  .scroll-down span:nth-child(3) {
    animation-delay: -0.4s;
  }
  
  @keyframes scroll-down {
    0% {
      opacity: 0;
      transform: rotate(45deg) translate(-15px, -15px);
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      transform: rotate(45deg) translate(15px, 15px);
    }
  }
  



  /*Hexagon Service Style*/
  .hexagon-class::before
  {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    -webkit-clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
    clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
    z-index: -1;
  }

  .hexagon-class::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    -webkit-clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
    clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
    z-index: -1;
    background: #424242;
    transform: scale(1.055);
    opacity: 1; 
    transition: opacity 0.5s ease;
  }
  
  .hexagon-class:hover::before {
    opacity: 0.5;
  }
  


  
  /*Skill Style*/
  .skill::before{
    content: '';
    position: absolute;
    width: 160%;
    height: 160%;
    background-image: conic-gradient(transparent, transparent, transparent, #ffffff);
    animation: animate 5s linear infinite;
  }

  .skill::after{
    content: '';
    position: absolute;
    width: 160%;
    height: 160%;
    background-image: conic-gradient(transparent, transparent, transparent, #0178d4);
    animation: animate 5s linear infinite;
    animation-delay: -2.5s;
  }

  @keyframes animate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  


  
  /*Preloader*/
  #preloader {
    width: 100%;
    height: 100%;
    z-index: 999999;
    background-color: #1f1f1f;
    background-image: url(./assets/infinite-spinner.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 150px;
  }
  
  #preloader-none {
    opacity: 0;
  }
  
  #no-scroll {
    overflow: hidden;
    height: 100vh;
  }
  


  
  /*Project for MyWork*/
  :root {
    --cards: 4;
    --cardHeight: 400px;
    --cardTopPadding: 5.2em;
    --cardMargin: 4vw;
  }

  @media (max-width: 1200px) {
    :root {
      --cardTopPadding: 4.5em;
    }
  }

  @media (max-width: 570px) {
    :root {
      --cardTopPadding: 4.2em;
    }
  }

  @media (max-width: 500px) {
    :root {
      --cardTopPadding: 4.1em;
    }
  }

  .container {
    width: 70%;
    margin: 0 auto;
  }

  @media (max-width: 1200px) {
    .container {
      width: 75%;
    }
  }

  @media (max-width: 994px) {
    .container {
      width: 90%;
    }
  }
  
  
  #cards {
    list-style: none;
    padding-left: 0;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(var(--cards), var(--cardHeight));
    gap: var(--cardMargin);
    padding-bottom: calc(var(--cards) * var(--cardTopPadding));
    margin-bottom: var(--cardMargin);
  }
  
  #card1 {
    --index: 1;
  }
  #card2 {
    --index: 2;
  }
  #card3 {
    --index: 3;
  }
  #card4 {
    --index: 4;
  }
  
  .card {
    position: sticky;
    top: 0;
    padding-top: calc(var(--index) * var(--cardTopPadding));
  }
  
  #card1 .card-body {
    background: linear-gradient(to right, #0f1117, #131430);
  }
  #card2 .card-body {
    /* background-color: #7EC4CF; */
    background: linear-gradient(to right, #0d1224, #0a0d37);
  }
  #card3 .card-body {
    background: linear-gradient(to right, #0d1224, #0a0d37);
  }
  #card4 .card-body {
    background: linear-gradient(to right, #0d1224, #0a0d37);
  }
  
  .card-body {
    box-sizing: border-box;
    border-color: #312e81;
    border-width: 0.8px;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.3);
    height: fit-content;
    transition: all 0.5s;
  }